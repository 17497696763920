"use client";

import { ReactNode } from "react";

// New client component to track events
interface TrackEventWrapperProps {
  eventNames: string[];
  params?: Record<string, any>;
  children: ReactNode;
  className?: string;
}

const TrackEventWrapper: React.FC<TrackEventWrapperProps> = ({
  eventNames,
  params = {},
  children,
  className,
}) => {
  const handleClick = () => {
    console.log("tracking is working");
    import("react-facebook-pixel")
      .then((ReactPixel) => {
        eventNames.forEach((eventName) => {
          ReactPixel.default.trackCustom(eventName, params);
          console.log("Tracking completed for event", eventName);
        });
      })
      .catch((error) => {
        console.error("Failed to load react-facebook-pixel", error);
      });
    console.log("tracking is finished?");
  };

  return (
    <button
      onClick={handleClick}
      className={`${className} w-auto cursor-pointer`}
    >
      {children}
    </button>
  );
};

export { TrackEventWrapper };
