import { ButtonsPropType } from "@/types/common";
import Link from "next/link";
import { BodyText } from "../texts/body-text";

export const PrimaryActiveButton = ({
  link,
  className,
  children,
  onClick,
}: ButtonsPropType) => {
  return (
    <Link
      href={link}
      onClick={onClick}
      className={`${className} mobile-shadow bg-primaryB4 border border-primaryB2 p-[15px] w-full h-[34px] mobile:h-[40px] flex items-center justify-center gap-x-2.5`}
    >
      <BodyText className="!text-[14px] text-neutralN3">{children}</BodyText>
    </Link>
  );
};
