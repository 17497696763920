"use client";

import { useEffect, useState } from "react";
import { MobileHeaderDetails } from "./mobile-header-details";
import { Logo } from "../logo";
import { isLoggedIn } from "../../../actions/auth";
import { usePathname } from "next/navigation";
import { useUpdateShowNavMobile } from "@/store/update-show-nav-mobile";

export const MobileHeader = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isUserLoggedIn, setUserIsLoggedIn] = useState(false);
  const pathName = usePathname();
  const { showHeaderNav } = useUpdateShowNavMobile((state) => state);

  const isAuth = pathName === "/login" || pathName === "/verify-otp";

  useEffect(() => {
    (async () => {
      const loggedIn = await isLoggedIn();

      if (loggedIn) {
        setUserIsLoggedIn(true);
      } else {
        setUserIsLoggedIn(false);
      }
    })();
  }, []);

  return (
    <div
      style={{ translate: "all 500ms" }}
      className={`hidden mobile:mx-0 mobile:!overflow-x-hidden mobile:justify-normal mobile:px-5 border-b border-[#0000001F] mobile:w-screen sticky top-0 left-0 bg-white z-50 ${
        showHeaderNav ? "mobile:py-[15px] mobile:block" : "py-0 mobile:hidden"
      }`}
    >
      {isExpanded && (
        <MobileHeaderDetails
          isUserLoggedIn={isUserLoggedIn}
          onClose={() => setIsExpanded(false)}
        />
      )}

      <div
        className={`hidden w-full mobile:flex-col mobile:gap-y-2 ${
          showHeaderNav ? "mobile:flex" : "mobile:hidden"
        }`}
      >
        <div
          className={`${
            showHeaderNav ? "mobile:flex" : "mobile:hidden"
          } mobile:items-center mobile:h-full mobile:w-full mobile:justify-between`}
        >
          <Logo />

          {!isAuth && (
            <div
              onClick={() => setIsExpanded(true)}
              className="h-[20px] overflow-hidden hidden w-[20px] mobile:flex cursor-pointer flex-col gap-[5px] -mb-2.5"
            >
              <div className="border border-primaryB7 rounded-full" />
              <div className="border border-primaryB7 rounded-full" />
              <div className="border border-primaryB7 rounded-full" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// <div className="hidden w-full pt-2 mobile:flex mobile:flex-col mobile:gap-y-2">
//   <div className="mobile:flex mobile:items-center mobile:h-full mobile:w-full mobile:justify-between">
//     <Logo />
//     <motion.div
//       variants={{
//         visible: {
//           opacity: 1,
//           scale: 1,
//           transition: { duration: 0.5 },
//         },
//         hidden: { opacity: 0, scale: 0 },
//       }}
//       initial={false}
//     >
//       {!isExpanded && (
//         <div
//           onClick={() => setIsExpanded(true)}
//           className="h-[20px] overflow-hidden hidden w-[25px] mobile:flex cursor-pointer flex-col gap-[5px]"
//         >
//           <div className="border border-black" />
//           <div className="border border-black" />
//           <div className="border border-black" />
//         </div>
//       )}
//       {isExpanded && (
//         <LiaTimesSolid
//           className="text-black"
//           onClick={() => setIsExpanded(false)}
//           size={25}
//         />
//       )}
//     </motion.div>
//   </div>
//   <AnimatePresence>
//     {isExpanded && (
//       <motion.div
//         animate="open"
//         key="content"
//         initial="collapsed"
//         variants={{
//           open: { opacity: 1, height: "auto" },
//           collapsed: { opacity: 0, height: 0 },
//         }}
//         transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
//       >
//         <div className="hidden mobile:flex flex-col mobile:gap-y-2">
//           <div
//             style={{ color: "#999999" }}
//             className="border border-[#0000001F] py-3 px-6 rounded-l-xl mobile:rounded-xl flex mobile:flex-col items-center gap-6 font-medium text-[16px]"
//           >
//             <Link className="hover:text-slate-900" href="/about">
//               About
//             </Link>
//             <Link className="hover:text-slate-900" href="/#image-section">
//               Luxury Homes
//             </Link>
//             <Link className="hover:text-slate-900" href="/jugglers-rest">
//               Hostels
//             </Link>
//             <Link className="hover:text-slate-900" href="/contact">
//               Contact us
//             </Link>
//           </div>
//           <div className="text-black border border-[#0000001F] rounded-r-xl mobile:rounded-xl py-3 px-6 flex items-center gap-3 font-medium text-[16px]">
//             <FaWhatsapp size={20} className="font-medium" />
//             <span>Book • 9380834941</span>
//           </div>
//         </div>{" "}
//       </motion.div>
//     )}
//   </AnimatePresence>
// </div>
