"use client";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SearchDataType } from "../types/common";

interface Props {
  setSearchData:
    | Dispatch<SetStateAction<SearchDataType>>
    | ((searchData: SearchDataType) => void);

  maxOccupancy?: number;
}

const useLocalStorageSearchData = ({ setSearchData, maxOccupancy }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, [setIsLoaded, setSearchData]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storage = localStorage.getItem("searchData");
      if (storage) {
        const data = JSON.parse(storage);

        if (!data.expiry) {
          localStorage.removeItem(storage);
          setSearchData({
            guests: {
              noOfChildren: 0,
              noOfAdults: 0,
              noOfPets: 0,
              noOfTotalGuests: 0,
            },
            checkInDate: null,
            checkOutDate: null,
            city: "",
          });
          return;
        }

        const now = new Date();
        if (now.getTime() > data.expiry) {
          localStorage.removeItem(storage);
          setSearchData({
            guests: {
              noOfChildren: 0,
              noOfAdults: 0,
              noOfPets: 0,
              noOfTotalGuests: 0,
            },
            checkInDate: null,
            checkOutDate: null,
            city: "",
          });
          return;
        }

        const checkInDate = data?.checkInDate
          ? new Date(data.checkInDate)
          : null;
        const checkOutDate = data?.checkOutDate
          ? new Date(data.checkOutDate)
          : null;
        const city = data?.city ? data.city : "";
        const guests =
          maxOccupancy && data.guests.noOfTotalGuests > maxOccupancy
            ? {
                noOfChildren: 0,
                noOfAdults: maxOccupancy,
                noOfPets: 0,
                noOfTotalGuests: maxOccupancy,
              }
            : data?.guests
            ? data.guests
            : {
                noOfChildren: 0,
                noOfAdults: 0,
                noOfPets: 0,
                noOfTotalGuests: 0,
              };

        setSearchData({ checkInDate, checkOutDate, guests, city });
      }
    }
  }, [setSearchData]);

  return isLoaded;
};

export default useLocalStorageSearchData;
