import Image from "next/image";
import Link from "next/link";

export const Logo = () => {
  return (
    <Link href="/">
      <Image
        src={
          "https://res.cloudinary.com/dz3tveb47/image/upload/v1741425295/logo_for_websiter_1_1_iz3hbc.png"
        }
        alt="Logo"
        width={1000}
        height={1000}
        className="w-[215px] h-[50px] -mt-4 -ml-4 object-cover mobile:h-[30px] mobile:w-[120px] mobile:-mt-3 mobile:-mb-1 mobile:-ml-2"
      />
    </Link>
  );
};
