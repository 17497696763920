import { logout } from "../../../actions/logout";
import toast from "react-hot-toast";
import { PrimaryActiveButton } from "../common/buttons/primary-active-button";
import { FormEvent } from "react";

interface Props {
  isUserLoggedIn: boolean;
}

export const AuthButton = ({ isUserLoggedIn }: Props) => {
  const handleLogout = (event: FormEvent) => {
    event.preventDefault();
    const isLogout = logout();
    if (isLogout.status === "error") {
      toast.error(isLogout.message);
      return;
    }

    toast.success("Logged out successfully");
  };

  return (
    <>
      {!isUserLoggedIn ? (
        <PrimaryActiveButton className=" mobile:!h-10" link="/login">
          sign in
        </PrimaryActiveButton>
      ) : (
        <PrimaryActiveButton
          className=" mx-auto mobile:!h-10"
          onClick={(event) => handleLogout(event)}
          link="#"
        >
          log out
        </PrimaryActiveButton>
      )}
    </>
  );
};
