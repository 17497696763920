export const ProfileIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.97754C6.48603 2.97754 2 7.46358 2 12.9775C2 18.4915 6.48603 22.9775 12 22.9775C17.514 22.9775 22 18.4915 22 12.9775C22 7.46358 17.514 2.97754 12 2.97754ZM12 4.47754C16.7033 4.47754 20.5 8.27423 20.5 12.9775C20.5 15.2056 19.641 17.2234 18.2441 18.7373C17.0508 16.503 14.7047 14.9775 12 14.9775C9.29527 14.9775 6.94916 16.503 5.75586 18.7373C4.35902 17.2234 3.5 15.2056 3.5 12.9775C3.5 8.27423 7.29669 4.47754 12 4.47754ZM12 7.97754C10.895 7.97754 10 8.87254 10 9.97754V10.9775C10 12.0825 10.895 12.9775 12 12.9775C13.105 12.9775 14 12.0825 14 10.9775V9.97754C14 8.87254 13.105 7.97754 12 7.97754ZM12 16.4775C14.2773 16.4775 16.1997 17.8434 17.0742 19.79C15.658 20.8456 13.9069 21.4775 12 21.4775C10.0931 21.4775 8.34202 20.8456 6.92578 19.79C7.80027 17.8434 9.72268 16.4775 12 16.4775Z"
        fill="#65605A"
      />
    </svg>
  );
};
