export const PhoneIcon = ({
  color,
  height,
  width,
}: {
  color: string;
  height: string;
  width: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 35 35`}
      fill="none"
    >
      <path
        d="M10.5108 2.92249C10.1824 2.90537 9.84587 2.95001 9.51958 3.06206L6.85926 3.97636C3.89334 4.99422 2.22159 8.22163 3.19634 11.2196C4.32613 14.6905 6.57718 19.5708 11.0035 23.9971C15.4299 28.4235 20.3101 30.6745 23.7811 31.8043C26.779 32.7791 30.0064 31.1073 31.0243 28.1414L31.9386 25.4811H31.9415C32.389 24.1753 31.7424 22.7211 30.4746 22.177L25.5413 20.0608C24.4391 19.5884 23.152 19.8382 22.3028 20.6874L20.2833 22.704C19.5898 22.3723 17.9881 21.531 15.7289 19.2718C13.4697 17.0126 12.628 15.4104 12.2967 14.7173L14.3133 12.6979C15.1612 11.85 15.412 10.5631 14.9399 9.45936L12.8236 4.52894V4.52609C12.4152 3.57454 11.4959 2.97387 10.5108 2.92249ZM10.2288 5.12993C10.4688 5.04752 10.7121 5.15416 10.8127 5.38913L12.929 10.3195C13.0519 10.607 12.9862 10.9316 12.7666 11.1513L10.1804 13.7404C10.0286 13.8919 9.92466 14.0848 9.88161 14.295C9.83856 14.5052 9.85827 14.7234 9.93828 14.9224C9.93828 14.9224 11.0858 17.722 14.1823 20.8184C17.2787 23.9148 20.0783 25.0624 20.0783 25.0624C20.2773 25.1424 20.4955 25.1621 20.7057 25.119C20.9158 25.076 21.1087 24.9721 21.2603 24.8203L23.8494 22.234C24.0706 22.0128 24.395 21.949 24.6811 22.0717L29.6115 24.188C29.8458 24.2885 29.954 24.5289 29.8707 24.7719L28.9564 27.4322C28.3147 29.3021 26.323 30.3283 24.4589 29.7222C21.2274 28.6703 16.6696 26.57 12.5502 22.4505C8.43071 18.331 6.33033 13.7733 5.27845 10.5417C4.67236 8.67761 5.69857 6.68596 7.56849 6.04424L10.2288 5.12993Z"
        fill={color}
      />
    </svg>
  );
};
