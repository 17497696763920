export const AppDownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <mask
        id="mask0_971_6062"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="35"
        height="35"
      >
        <rect width="35" height="35" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_971_6062)">
        <path
          d="M22.3238 16.042L17.5 20.8658L12.6762 16.042L13.7083 15.0099L16.7708 18.0556V11.2182H18.2292V18.0556L21.2917 15.0099L22.3238 16.042ZM14.9198 28.662H20.0802V27.5402H14.9198V28.662ZM11.1059 32.0837C10.4346 32.0837 9.87413 31.8588 9.42448 31.4092C8.97483 30.9595 8.75 30.399 8.75 29.7277V5.27293C8.75 4.60161 8.97483 4.04112 9.42448 3.59147C9.87413 3.14182 10.4346 2.91699 11.1059 2.91699H23.8941C24.5654 2.91699 25.1259 3.14182 25.5755 3.59147C26.0252 4.04112 26.25 4.60161 26.25 5.27293V29.7277C26.25 30.399 26.0252 30.9595 25.5755 31.4092C25.1259 31.8588 24.5654 32.0837 23.8941 32.0837H11.1059ZM10.2083 25.5773V29.7277C10.2083 29.9523 10.3018 30.1581 10.4887 30.345C10.6756 30.5319 10.8814 30.6253 11.1059 30.6253H23.8941C24.1186 30.6253 24.3244 30.5319 24.5113 30.345C24.6982 30.1581 24.7917 29.9523 24.7917 29.7277V25.5773H10.2083ZM10.2083 24.119H24.7917V8.02116H10.2083V24.119ZM10.2083 6.56283H24.7917V5.27293C24.7917 5.04835 24.6982 4.8426 24.5113 4.65569C24.3244 4.46878 24.1186 4.37533 23.8941 4.37533H11.1059C10.8814 4.37533 10.6756 4.46878 10.4887 4.65569C10.3018 4.8426 10.2083 5.04835 10.2083 5.27293V6.56283Z"
          fill="#51311F"
        />
        <path
          d="M10.2083 25.5773V29.7277C10.2083 29.9523 10.3018 30.1581 10.4887 30.345C10.6756 30.5319 10.8814 30.6253 11.1059 30.6253H23.8941C24.1186 30.6253 24.3244 30.5319 24.5113 30.345C24.6982 30.1581 24.7917 29.9523 24.7917 29.7277V25.5773H10.2083ZM10.2083 25.5773V30.6253M10.2083 6.56283H24.7917V5.27293C24.7917 5.04835 24.6982 4.8426 24.5113 4.65569C24.3244 4.46878 24.1186 4.37533 23.8941 4.37533H11.1059C10.8814 4.37533 10.6756 4.46878 10.4887 4.65569C10.3018 4.8426 10.2083 5.04835 10.2083 5.27293V6.56283ZM10.2083 6.56283V4.37533M22.3238 16.042L17.5 20.8658L12.6762 16.042L13.7083 15.0099L16.7708 18.0556V11.2182H18.2292V18.0556L21.2917 15.0099L22.3238 16.042ZM14.9198 28.662H20.0802V27.5402H14.9198V28.662ZM11.1059 32.0837C10.4346 32.0837 9.87413 31.8588 9.42448 31.4092C8.97483 30.9595 8.75 30.399 8.75 29.7277V5.27293C8.75 4.60161 8.97483 4.04112 9.42448 3.59147C9.87413 3.14182 10.4346 2.91699 11.1059 2.91699H23.8941C24.5654 2.91699 25.1259 3.14182 25.5755 3.59147C26.0252 4.04112 26.25 4.60161 26.25 5.27293V29.7277C26.25 30.399 26.0252 30.9595 25.5755 31.4092C25.1259 31.8588 24.5654 32.0837 23.8941 32.0837H11.1059ZM10.2083 24.119H24.7917V8.02116H10.2083V24.119Z"
          stroke="#51311F"
          strokeWidth="0.8"
        />
      </g>
    </svg>
  );
};
