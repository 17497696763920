"use client";

import { FaChevronDown } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { BodyText } from "../common/texts/body-text";
import "./select-destination.css";
import { RadioOption } from "../radio-option";
import { NotSelectedText, SelectedText } from "./main-search-bar-texts";
import {
  HeaderNotSelectedText,
  HeaderSelectedText,
} from "./header-search-bar-texts";

const cities = ["Bengaluru", "Mumbai", "Philadelphia", "Hyderabad"];

interface Props {
  handleSelectCity: (city: string) => void;
  header?: boolean;
  destination: string;
  children?: React.ReactNode;
  mainPage?: boolean;
  isDestinationOpen: boolean;
  setIsDestinationOpen: (isOpen: boolean) => void;
}

export const SelectDestination = ({
  header,
  destination,
  children,
  mainPage,
  handleSelectCity,
  isDestinationOpen,
  setIsDestinationOpen,
}: Props) => {
  const [option, setOption] = useState(destination);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;

      // Check if click is outside both trigger and dropdown content
      if (
        triggerRef.current &&
        !triggerRef.current.contains(target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(target)
      ) {
        setIsDestinationOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsDestinationOpen]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setIsDestinationOpen(false);
    });
  }, [setIsDestinationOpen]);

  useEffect(() => {
    setOption(destination);
  }, [destination]);

  return (
    <>
      <div
        onClick={() => setIsDestinationOpen(!isDestinationOpen)}
        ref={triggerRef}
        className={`relative w-auto ${header && "!w-auto"} ${
          mainPage && "!w-[30%]"
        } mobile:w-auto`}
      >
        <div
          className={`h-full w-full ${
            header && "px-0 h-auto w-auto flex flex-col gap-y-1"
          } ${
            isDestinationOpen && "opacity-75"
          } cursor-pointer px-4 text-[#5F5853] flex flex-col mobile:justify-center mobile:w-full mobile:border-[0.5px] mobile:border-neutralN6 mobile:rounded-[2.5px] ${
            isDestinationOpen && "mobile:border-primaryB6"
          } mobile:p-2.5 mobile:flex mobile:flex-col mobile:gap-y-0 items-start ${
            mainPage && "flex flex-col gap-y-2"
          }`}
        >
          {children}

          {!header && (
            <div className={`mobile:hidden flex items-center gap-x-7 `}>
              {destination ? (
                <SelectedText text={destination} />
              ) : (
                <NotSelectedText text="select destination" />
              )}

              <FaChevronDown className={`w-2 h-2 text-primaryB7`} />
            </div>
          )}

          {header && (
            <div className="mobile:hidden flex items-center gap-x-1.5">
              {destination ? (
                <HeaderSelectedText text={destination} />
              ) : (
                <HeaderNotSelectedText text="select destination" />
              )}

              <FaChevronDown className={`w-2 h-2 text-primaryB7`} />
            </div>
          )}

          <BodyText
            className={`hidden mobile:block !font-medium !text-[16px] ${
              destination ? "text-primaryB7" : "text-neutralN6"
            }`}
          >
            {destination ? destination : "select destination"}
          </BodyText>
        </div>
      </div>
      {isDestinationOpen && (
        <div
          style={{ width: mainPage ? "50%" : "100%" }}
          ref={dropdownRef}
          className="z-[42] mobile:static border-2 mobile:border-0 border-neutralN5 absolute p-4 mobile:p-0 top-[100%] left-0 h-auto mt-2 mobile:mt-0 bg-neutralN2 mobile:bg-white rounded-[2.5px] flex flex-col gap-y-2"
        >
          {cities.map((item, index) => (
            <RadioOption
              key={item + index}
              option={option}
              setOption={setOption}
              value={item}
              handleSelectValue={handleSelectCity}
              text={item}
            />
          ))}
        </div>
      )}
    </>
  );
};
