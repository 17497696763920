interface Props {
  onClick?: () => void;
}

export const MobileCloseIcon = ({ onClick }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g opacity="0.5">
        <path
          d="M16.8733 2.4865C16.7082 2.4904 16.5514 2.55944 16.4371 2.67856L9.99989 9.11573L3.56272 2.67856C3.50429 2.61861 3.43443 2.57101 3.35726 2.53858C3.2801 2.50615 3.1972 2.48955 3.1135 2.48976C2.98935 2.49012 2.86813 2.52743 2.76527 2.59695C2.66242 2.66647 2.58259 2.76504 2.53598 2.8801C2.48936 2.99516 2.47806 3.1215 2.50352 3.243C2.52898 3.36451 2.59004 3.47568 2.67893 3.56235L9.1161 9.99952L2.68706 16.4286C2.62708 16.4862 2.57919 16.5551 2.5462 16.6315C2.51321 16.7078 2.49579 16.7899 2.49494 16.8731C2.4941 16.9562 2.50985 17.0387 2.54128 17.1157C2.57271 17.1927 2.61919 17.2626 2.67799 17.3214C2.73678 17.3802 2.80672 17.4267 2.88371 17.4581C2.96069 17.4896 3.04318 17.5053 3.12633 17.5045C3.20948 17.5036 3.29162 17.4862 3.36795 17.4532C3.44428 17.4202 3.51326 17.3723 3.57085 17.3123L9.99989 10.8833L16.4306 17.3156C16.4887 17.3738 16.5577 17.4199 16.6337 17.4514C16.7096 17.4828 16.7911 17.499 16.8733 17.499C16.9555 17.499 17.0369 17.4828 17.1129 17.4514C17.1889 17.4199 17.2579 17.3738 17.316 17.3157C17.3742 17.2575 17.4203 17.1885 17.4517 17.1125C17.4832 17.0366 17.4994 16.9551 17.4994 16.8729C17.4994 16.7907 17.4832 16.7093 17.4517 16.6333C17.4202 16.5573 17.3741 16.4883 17.316 16.4302L10.8837 9.99952L17.3209 3.56235C17.4117 3.47512 17.4741 3.36246 17.4999 3.23914C17.5256 3.11582 17.5135 2.9876 17.4651 2.87129C17.4167 2.75498 17.3343 2.656 17.2287 2.58734C17.1231 2.51868 16.9992 2.48353 16.8733 2.4865Z"
          fill="#2B1C13"
          stroke="#2B1C13"
        />
      </g>
    </svg>
  );
};
