"use client";

import { usePathname, useRouter } from "next/navigation";
import { OffersIcon } from "../../../../assets/offers-icon";
import { NeutralButton } from "../buttons/neutral-button";
import { LabelText } from "../texts/label-text";
import * as gtag from "./../../../../lib/gtag";

export const OffersTab = () => {
  const router = useRouter(); // Use the router for navigation
  const pathName = usePathname();
  const isOffersPage = pathName === "/offers";

  const handleButtonClick = () => {
    // Call the analytics event
    gtag.event({
      action: "offers_available",
      category: "click",
      label: "User clicked the offers available",
      value: 1,
    });
  };

  return (
    <NeutralButton
      className={`!pr-[25px] !pl-[15px] ${isOffersPage && "bg-neutralN9"}`} link="/offers"
      onClick={handleButtonClick} // Trigger the click handler
    >
      <OffersIcon color={isOffersPage ? "#FBF7F2" : ""} />
      <LabelText className={isOffersPage ? "text-neutralN2" : "text-neutralN8"}>
        offers available
      </LabelText>
    </NeutralButton >
  );
};
