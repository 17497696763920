import ModalOverlay from "@/components/modal";
import Link from "next/link";
import { AuthButton } from "./auth-button";
import { useEffect, useState } from "react";
import { BodyText } from "../common/texts/body-text";
import { TrackEventWrapper } from "@/app/(main)/_components/widgets/splash";
import { SearchIcon } from "../../../assets/search-icon";
import { PhoneIcon } from "../../../assets/phone-icon";
import { AppDownloadIcon } from "../../../assets/app-download-icon";
import { UserIcon } from "../../../assets/user-icon";
import { MobileBookingIcon } from "../../../assets/mobile-booking-icon";
import { Logo } from "../logo";
import { MobileCloseIcon } from "../../../assets/mobile-close-icon";

interface Props {
  onClose?: () => void;
  isUserLoggedIn: boolean;
}

interface ItemType {
  text: string;
  link: string;
  icon: React.ReactNode;
}

const data: ItemType[] = [
  {
    link: "/listings",
    text: "Explore all stays",
    icon: <SearchIcon color="#51311F" />,
  },
  {
    link: "https://wa.me/918608600718?text=Hi%20Spacez,%20I'm%20interested%20to%20know%20about%20something",
    text: "contact: 860 8600 718",
    icon: <PhoneIcon height="35" width="35" color="#51311F" />,
  },
  {
    link: "https://savz.live/spacezapp",
    text: "download mobile app",
    icon: (
      <TrackEventWrapper eventNames={["header/spacezapp"]}>
        <AppDownloadIcon />
      </TrackEventWrapper>
    ),
  },
];

const loggedInItems = [
  {
    link: "/profile",
    text: "your profile",
    icon: <UserIcon color="#51311F" />,
  },
  {
    link: "/bookings",
    text: "view bookings",
    icon: <MobileBookingIcon color="#51311F" />,
  },
  ...data,
];

export const MobileHeaderDetails = ({ onClose, isUserLoggedIn }: Props) => {
  const [itemsData, setItemsData] = useState(data);

  useEffect(() => {
    const items = isUserLoggedIn ? loggedInItems : data;
    setItemsData(items);
  }, [isUserLoggedIn]);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <ModalOverlay
      onClose={() => {}}
      childrenClassName="!p-0"
      overlayClassName="!p-0"
      className="!z-[999] !rounded-none fixed !h-screen !w-screen mobile:!w-screen !top-0 !left-0 !overflow-x-hidden !py-0 !px-0 !mx-0"
    >
      <div className="w-full h-full relative">
        <header
          className={`py-[15px] border-b border-[#0000001F] mobile:px-5 w-screen flex items-center justify-between mx-auto mobile:!overflow-x-hidden`}
        >
          <div className="mobile:flex mobile:items-center mobile:h-full mobile:w-full mobile:justify-between">
            <TrackEventWrapper eventNames={["header/logo"]}>
              <Logo />
            </TrackEventWrapper>
            <MobileCloseIcon onClick={onClose} />
          </div>
        </header>

        <div className="mt-[40px] px-[20px] flex flex-col gap-y-7 w-full">
          <div className="flex flex-col gap-y-7">
            {itemsData.map(({ text, link, icon }) => (
              <Item key={text} icon={icon} text={text} link={link} />
            ))}
          </div>
          <div
            className="w-full mt-4 mx-auto"
            onClick={() => {
              if (isUserLoggedIn && onClose) {
                window.location.href = "/";
              }
            }}
          >
            <AuthButton isUserLoggedIn={isUserLoggedIn} />
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};

const Item = ({ text, link, icon }: ItemType) => {
  return (
    <Link href={link} className="w-full flex items-center gap-x-2">
      <div className="w-[90%] p-2.5 border-[0.5px] border-neutralN6 rounded-[2.5px]">
        <BodyText className="text-primaryB6 !text-[16px] !font-normal">
          {text}
        </BodyText>
      </div>
      {icon}
    </Link>
  );
};
