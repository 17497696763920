export const SearchIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <path
        d="M14.5827 2.91699C8.15231 2.91699 2.91602 8.15329 2.91602 14.5837C2.91602 21.014 8.15231 26.2503 14.5827 26.2503C17.3969 26.2503 19.8849 25.1203 21.9028 23.4533L30.2142 31.7646C30.3159 31.8664 30.4367 31.9471 30.5696 32.0022C30.7026 32.0573 30.8451 32.0856 30.989 32.0856C31.1329 32.0857 31.2753 32.0573 31.4083 32.0023C31.5412 31.9472 31.662 31.8665 31.7638 31.7647C31.8655 31.663 31.9462 31.5422 32.0013 31.4093C32.0563 31.2763 32.0847 31.1338 32.0847 30.9899C32.0847 30.846 32.0563 30.7036 32.0012 30.5706C31.9462 30.4377 31.8654 30.3169 31.7637 30.2152L23.4523 21.9038C25.1193 19.8858 26.2493 17.3979 26.2493 14.5837C26.2493 8.15329 21.0131 2.91699 14.5827 2.91699ZM14.5827 5.10449C19.8308 5.10449 24.0618 9.3355 24.0618 14.5837C24.0618 19.8318 19.8308 24.0628 14.5827 24.0628C9.33452 24.0628 5.10352 19.8318 5.10352 14.5837C5.10352 9.3355 9.33452 5.10449 14.5827 5.10449Z"
        fill={color}
      />
    </svg>
  );
};
