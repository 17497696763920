"use client";

import { ChevronDownIcon } from "@radix-ui/react-icons";
import { NeutralButton } from "../common/buttons/neutral-button";
import { FormEvent, useEffect, useRef, useState } from "react";
import { ProfileIcon } from "../../../assets/profile-icon";

interface Props {
  children: React.ReactNode;
}

export const ProfileDropdown = ({ children }: Props) => {
  const [isClicked, setIsClicked] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: FormEvent) => {
    event.preventDefault();
    setIsClicked((prev) => !prev);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;

      // Check if click is outside both trigger and dropdown content
      if (
        triggerRef.current &&
        !triggerRef.current.contains(target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(target)
      ) {
        setIsClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={triggerRef} className="relative">
      <NeutralButton onClick={handleClick} link="#" className="!gap-x-1">
        <ProfileIcon />
        <ChevronDownIcon className="text-neutralN8" />
      </NeutralButton>
      {isClicked && (
        <div
          ref={dropdownRef}
          className="absolute bg-neutralN2 border-[0.5px] border-neutralN5 right-0 top-[120%] w-[150px] rounded-[2.5px] !p-0"
        >
          {children}
        </div>
      )}
    </div>
  );
};
