import { create } from "zustand";

interface UpdateShowNavMobile {
  showBottomNav: boolean;
  setShowBottomNav: (showBottomNav: boolean) => void;
  showHeaderNav: boolean;
  setShowHeaderNav: (showHeaderNav: boolean) => void;
  shadowSearchBar: boolean;
  setShadowSearchBar: (shadowSearchBar: boolean) => void;
}

export const useUpdateShowNavMobile = create<UpdateShowNavMobile>((set) => {
  return {
    showBottomNav: true,
    setShowBottomNav: (showBottomNav) =>
      set(() => ({
        showBottomNav,
      })),
    showHeaderNav: true,
    setShowHeaderNav: (showHeaderNav) =>
      set(() => ({
        showHeaderNav,
      })),
    shadowSearchBar: false,
    setShadowSearchBar: (shadowSearchBar) =>
      set(() => ({
        shadowSearchBar,
      })),
  };
});
