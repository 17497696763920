"use client";

import { SearchButton } from "@/components/common/buttons/search-button";
import { AddGuests } from "@/components/user-data-inputs/add-guests";
import { DesktopDateSelector } from "@/components/user-data-inputs/date-selector";
import { SelectDestination } from "@/components/user-data-inputs/select-destination";
import useLocalStorageSearchData from "@/hooks/use-local-storage-search-data";
import { addSearchDataInLocalStorage } from "@/lib/search-data";
import * as gtag from "./../../../../../lib/gtag";
import {
  HeaderNotSelectedText,
  HeaderSelectedText,
} from "@/components/user-data-inputs/header-search-bar-texts";
import { useUpdateHomeSearchData } from "@/store/update-home-search-data";
import { useState } from "react";

type Guests = {
  noOfAdults: number;
  noOfChildren: number;
  noOfPets: number;
  noOfTotalGuests: number;
};

export const SearchBar = () => {
  const [isDestinationOpen, setIsDestinationOpen] = useState(false);
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const { searchData, setSearchData } = useUpdateHomeSearchData(
    (state) => state
  );

  const handleCity = (selectedCity: string) => {
    setSearchData({ ...searchData, city: selectedCity });
    if (selectedCity) {
      setIsDestinationOpen(false);
      setIsDatesModalOpen(true);
    }
  };

  const handleDates = (date: [null | Date, null | Date]) => {
    const [startDate, endDate] = date;
    setSearchData({
      ...searchData,
      checkInDate: startDate,
      checkOutDate: endDate,
    });
    if (startDate && endDate) {
      setIsGuestsOpen(true);
    }
  };

  const handleGuests = (guests: Guests) => {
    setSearchData({ ...searchData, guests: { ...guests } });
  };

  const handleSearch = () => {
    if (searchData.city) {
      addSearchDataInLocalStorage(searchData);
      // @ts-ignore
      window.location = `/listings?city=${searchData.city}`;
    }
  };

  useLocalStorageSearchData({ setSearchData });

  return (
    <div className="single-page-header relative w-auto mobile:hidden p-[5px] rounded-[2.5px] border-[0.5px] border-secondaryG2 gap-x-[15px] flex items-center">
      <SelectDestination
        header
        destination={searchData.city}
        handleSelectCity={handleCity}
        isDestinationOpen={isDestinationOpen}
        setIsDestinationOpen={setIsDestinationOpen}
      >
        {searchData.city ? (
          <HeaderNotSelectedText text="where" />
        ) : (
          <HeaderSelectedText text="where" />
        )}
      </SelectDestination>

      <div className="h-6 my-auto w-[1px] bg-secondaryG2" />

      <DesktopDateSelector
        header
        checkInDate={searchData.checkInDate}
        checkOutDate={searchData.checkOutDate}
        handleDates={handleDates}
        isCheckIn
        isDatesOpen={isDatesModalOpen}
        setIsDatesOpen={setIsDatesModalOpen}
      >
        {searchData.checkInDate ? (
          <HeaderNotSelectedText text="check-in" />
        ) : (
          <HeaderSelectedText text="check in" />
        )}
      </DesktopDateSelector>

      <div className="h-6 my-auto w-[1px] bg-secondaryG2" />

      <DesktopDateSelector
        header
        isCheckIn={false}
        checkInDate={searchData.checkInDate}
        checkOutDate={searchData.checkOutDate}
        handleDates={handleDates}
        isDatesOpen={isDatesModalOpen}
        setIsDatesOpen={setIsDatesModalOpen}
      >
        {searchData.checkOutDate ? (
          <HeaderNotSelectedText text="check-out" />
        ) : (
          <HeaderSelectedText text="check out" />
        )}
      </DesktopDateSelector>

      <div className="h-6 my-auto w-[1px] bg-secondaryG2" />

      <AddGuests
        header
        guests={searchData.guests}
        handleGuests={handleGuests}
        isGuestsOpen={isGuestsOpen}
        setIsGuestsOpen={setIsGuestsOpen}
      >
        {searchData.guests.noOfTotalGuests ? (
          <HeaderNotSelectedText text="who" />
        ) : (
          <HeaderSelectedText text="who" />
        )}
      </AddGuests>

      <SearchButton header handleClick={handleSearch} />
    </div>
  );
};
