export const UserIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox={`0 0 35 35`}
      fill="none"
    >
      <path
        d="M17.4993 2.91699C9.45815 2.91699 2.91602 9.45913 2.91602 17.5003C2.91602 25.5415 9.45815 32.0837 17.4993 32.0837C25.5406 32.0837 32.0827 25.5415 32.0827 17.5003C32.0827 9.45913 25.5406 2.91699 17.4993 2.91699ZM17.4993 5.10449C24.3583 5.10449 29.8952 10.6413 29.8952 17.5003C29.8952 20.7496 28.6424 23.6922 26.6054 25.9C24.8652 22.6417 21.4437 20.417 17.4993 20.417C13.555 20.417 10.1335 22.6417 8.39331 25.9C6.35625 23.6922 5.10352 20.7496 5.10352 17.5003C5.10352 10.6413 10.6404 5.10449 17.4993 5.10449ZM17.4993 10.2087C15.8879 10.2087 14.5827 11.5139 14.5827 13.1253V14.5837C14.5827 16.1951 15.8879 17.5003 17.4993 17.5003C19.1108 17.5003 20.416 16.1951 20.416 14.5837V13.1253C20.416 11.5139 19.1108 10.2087 17.4993 10.2087ZM17.4993 22.6045C20.8204 22.6045 23.624 24.5963 24.8993 27.4352C22.8339 28.9747 20.2803 29.8962 17.4993 29.8962C14.7184 29.8962 12.1648 28.9747 10.0994 27.4352C11.3747 24.5963 14.1783 22.6045 17.4993 22.6045Z"
        fill={color}
      />
    </svg>
  );
};
